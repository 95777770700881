import React from 'react';

export default function PlayaEscamacaHighlight() {
  return (
    <div className="casa-horizon-highlight">
      <div className="overlay"></div>
      <h2>PLAYA ESCAMACA</h2>
    </div>
  );
}
